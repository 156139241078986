import * as React from "react";
import "./QtyCheckbox.scss";
import { IconCheck } from "../../config/Images";

export interface IQtyCheckboxProps {
  title: string;
  children: React.ReactNode;
  name: string;
  checked: boolean;
  onChange: (e: boolean) => {} | void;
}

export function QtyCheckbox(props: IQtyCheckboxProps) {
  const handleClick = () => {
    props.onChange(!props.checked);
  };

  return (
    <div className={(props.checked ? "checked" : "") + " qty-checkbox-pr"}>
      <div className="i" onClick={handleClick}>
        <span className="t">{props.title}</span>
        <img className="check-img" src={IconCheck} />
      </div>

      {props.checked && <div className="child-pr">{props.children}</div>}
    </div>
  );
}
