import * as React from "react";
import "./FormBox.scss";

export interface IFormBoxProps {
  children: React.ReactNode;
}

export function FormBox(props: IFormBoxProps) {
  return <div className="form-box">{props.children}</div>;
}

export interface IFormBoxTitleProps {
  title: string;
}

export function FormBoxTitle(props: IFormBoxTitleProps) {
  return <h1 className="form-box-title">{props.title}</h1>;
}

export interface IFormBoxFooterProps {
  children: React.ReactNode;
}

export function FormBoxFooter(props: IFormBoxFooterProps) {
  return <div className="form-box-footer">{props.children}</div>;
}
