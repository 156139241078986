import * as React from "react";

import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { Dialog } from "../../components/Dialog/Dialog";
import { ImageUploader } from "../../components/ImageUploader/ImageUploader";
import { ImgProject } from "../../config/Images";
import ProjectService from "../../api/services/Project.service";

type ICUProjectModalProps = {
  isOpen: any;
  setIsOpen: any;
  onSuccess?: () => {} | void;
};

export function CUProjectModal(props: ICUProjectModalProps) {
  const service = new ProjectService();
  const [data, setData] = React.useState<any>({
    name: "",
    img: null,
    owner: {
      name: "",
      email: "",
      phone: "",
    },
  });

  const handleChange = (e: any) => {
    if (e.target.type == "file") {
      setData({
        ...data,
        [e.target.name]: e.target.files[0],
      });
    } else if (e.target.type == "checkbox") {
    } else {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const res = await service.store(data);

    if (res.data && res.data.success) {
      props.setIsOpen(false);
      props.onSuccess && props.onSuccess();
    }
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="New Project"
    >
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        <div className="DialogBody">
          <div className="text-center">
            <ImageUploader
              name="img"
              placeholder={ImgProject}
              onChange={handleChange}
            />
          </div>
          <Input
            required
            name="name"
            placeholder="Project Name"
            onChange={handleChange}
            value={data.name}
          />

          <Input
            name="description"
            type="textarea"
            placeholder="Project Description"
            onChange={handleChange}
            value={data.description}
          />
        </div>

        <div className="DialogFooter">
          <Button
            className="mr-1"
            mode="link"
            onClick={() => {
              props.setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </div>
      </form>
    </Dialog>
  );
}
