import * as React from "react";

import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { Dialog } from "../../components/Dialog/Dialog";
import DocFolderService from "../../api/services/DocFolder.service";

type ICUFolderModalProps = {
  isOpen: any;
  setIsOpen: any;
  onSuccess?: () => {} | void;
};

export function CUFolderModal(props: ICUFolderModalProps) {
  const service = new DocFolderService();
  const [data, setData] = React.useState<any>({
    name: "",
  });

  const handleChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const res = await service.store(data);

    if (res.data && res.data.success) {
      props.setIsOpen(false);
      props.onSuccess && props.onSuccess();
    }
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="New Folder"
    >
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        <div className="DialogBody">
          <Input
            placeholder="Folder Name"
            name="name"
            required
            onChange={handleChange}
          />
        </div>

        <div className="DialogFooter">
          <Button
            className="mr-1"
            mode="link"
            onClick={() => {
              props.setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </div>
      </form>
    </Dialog>
  );
}
