import * as React from "react";
import "./DocsView.scss";
import { TreeView } from "../../components/TreeView/TreeView";
import { ITreeViewItemProps } from "../../components/TreeView/TreeViewItem";
import { useApp } from "../../contexts/App.context";
import { CUFolderModal } from "../../modals/docs/CUFolders.modal";
import DocFolderService from "../../api/services/DocFolder.service";
import IDocFolderGetResponse from "../../api/interfaces/IDocFolderGetResponse";
import { IconFolder } from "../../config/Images";
import DocService from "../../api/services/Doc.service";
import IDocDetailsGetResponse from "../../api/interfaces/IDocDetailsGetResponse";
import { useNavigate, useParams } from "react-router-dom";
import MarkdownEditor from "@uiw/react-markdown-editor";
import ContentShimmer from "react-content-shimmer";

export interface IDocsViewProps {}

export function DocsView(props: IDocsViewProps) {
  const { setExtraBtn } = useApp();
  const { doc_id } = useParams();
  const navigate = useNavigate();
  const [isNewModalOpen, setIsNewModalOpen] = React.useState(false);
  const [currentDoc, setCurrentDoc] =
    React.useState<IDocDetailsGetResponse | null>(null);
  const [items, setItems] = React.useState<Array<IDocFolderGetResponse> | null>(
    null
  );

  const [tree, setTree] = React.useState<Array<ITreeViewItemProps> | null>(
    null
  );
  const service = new DocFolderService();
  const docService = new DocService();

  const load: any = async () => {
    const res = await service.get();

    if (res.data && res.data.success) {
      setItems(res.data.data);
    }
  };

  React.useEffect(() => {
    setExtraBtn({
      text: "New Folder",
      action: () => {
        setIsNewModalOpen(isNewModalOpen ? false : true);
      },
    });

    load();

    return () => {
      setExtraBtn(null);
    };
  }, []);

  React.useEffect(() => {
    const loadTree = () => {
      let o: Array<ITreeViewItemProps> = [
        {
          title: "Documentation",
          type: "label",
        },
      ];

      if (items) {
        items.forEach((p) => {
          const k = {
            id: p.id,
            title: p.name,
            nodes: p.docs ?? [],
          };
          o.push(k);
        });

        setTree(o);
      }
    };

    loadTree();
  }, [items]);

  const handleCreateSuccess = () => {
    load();
  };

  const loadDoc = async (id: any) => {
    const res = await docService.show(id);

    if (res.data && res.data.success) {
      setCurrentDoc(res.data.data);
    }
  };

  React.useEffect(() => {
    setCurrentDoc(null);
    if (doc_id) {
      loadDoc(doc_id);
    }
  }, [doc_id]);

  const hanleItemOnClick = (e: ITreeViewItemProps) => {
    if (!e.isRoot && e.id) {
      navigate("/docs/" + e.id);
    }
  };

  return (
    <>
      <CUFolderModal
        onSuccess={handleCreateSuccess}
        isOpen={isNewModalOpen}
        setIsOpen={setIsNewModalOpen}
      />
      <div className="page with-sidebar">
        <aside className="sidebar">
          <TreeView
            addBtnUrl="/docs/create"
            itemOnClick={hanleItemOnClick}
            type="docs"
            nodes={tree}
          />
        </aside>

        <div className="page-content">
          {currentDoc ? (
            <>
              <header className="page-header">
                <div>
                  <ul className="breadcrumb">
                    <li>
                      <img src={IconFolder} height={18} />
                    </li>
                    <li>{currentDoc.docfolder.name} /</li>
                    <li>{currentDoc.title}</li>
                  </ul>
                </div>
                <div></div>
              </header>

              <div className="doc-preview">
                <MarkdownEditor.Markdown source={currentDoc.body} />
              </div>
            </>
          ) : doc_id ? (
            <ContentShimmer
              rows={2}
              rounded={"7px"}
              style={{
                width: "90%",
                height: "140px",
                margin: "15px auto 15px auto",
              }}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
