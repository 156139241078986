import * as React from "react";
import { IconFolder } from "../../config/Images";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import ProjectService from "../../api/services/Project.service";

import {
  FormBox,
  FormBoxFooter,
  FormBoxTitle,
} from "../../components/FormBox/FormBox";
import { Input } from "../../components/Input/Input";
import { GetProjectReqs } from "../../config/ProjectReq";
import { ImageRadioGroup } from "../../components/ImageRadio/ImageRadio";
import { IImageRadioItemProps } from "../../components/ImageRadio/ImageRadioItem";
import { QtyCheckbox } from "../../components/QtyCheckbox/QtyCheckbox";
import { useApp } from "../../contexts/App.context";
import ApplicationService from "../../api/services/Application.service";

export interface IApplicationCreateProps {}

export function ApplicationCreate(props: IApplicationCreateProps) {
  const { project_id } = useParams();
  const navigate = useNavigate();
  const { setIsLoading } = useApp();
  const [project, setProject] = React.useState<any>(null);
  const [step, setStep] = React.useState<number>(0);
  const reqs = GetProjectReqs();
  const [currentPlatform, setCurrentPlatform] = React.useState<any>(null);
  const [data, setData] = React.useState<any>({
    platform: "Web (Laravel)",
  });
  const projectService = new ProjectService();
  const service = new ApplicationService();
  interface IObject<T> {
    [index: string]: T;
  }

  const loadProject = async (id: any) => {
    const res = await projectService.show(id);

    if (res.data && res.data.success) {
      setProject(res.data.data);
    }
  };

  React.useEffect(() => {
    setProject(null);

    if (project_id) {
      loadProject(project_id);
    }
  }, [project_id]);

  React.useEffect(() => {
    if (step == 1) {
      if (!data.platform) {
        setStep(0);
        return;
      }

      let o: IObject<object> = reqs.req;

      if (o && o[data.platform]) {
        setCurrentPlatform(o[data.platform]);
      } else {
        alert("No Platform data");
      }
    }
  }, [step]);

  const handleChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const req = { ...data, project_id };
    const res = await service.store(req);

    setIsLoading(false);
    if (res.data && res.data.success) {
      navigate(`/projects/view/${project_id}/${res.data.data.id}`);
    }
  };

  const getInputByType = (item: any) => {
    switch (item.type) {
      case "text":
      case "textarea":
      case "number":
      case "email":
        return (
          <Input
            type={item.type}
            placeholder={Object.hasOwn(item, "title") ? item.title : ""}
            name={item.name}
            onChange={handleChange}
          />
        );
        break;

      case "radio_image_group":
        return (
          <ImageRadioGroup
            title="Select Platform"
            name={item.name}
            checked={data[item.name] ?? item.default}
            items={item.values}
            onChange={(e: IImageRadioItemProps) => {
              setData({
                ...data,
                [item.name]: e.value,
              });
            }}
          />
        );
        break;

      case "qty":
        return (
          <QtyCheckbox
            name={item.name}
            title={item.title}
            checked={data[item.name] ?? item.value ?? false}
            onChange={(e: boolean) => {
              setData({
                ...data,
                [item.name]: e,
              });
            }}
          >
            {getFields(item)}
          </QtyCheckbox>
        );
        break;

      default:
        break;
    }
  };

  const getFields = (item: any) => {
    if (Object.hasOwn(item, "fields")) {
      return item.fields.map((f: any) => {
        return getInputByType(f);
      });
    } else {
      return <></>;
    }
  };

  return (
    <>
      <header className="page-header">
        <div>
          <ul className="breadcrumb">
            <li>
              <img src={IconFolder} height={18} />
            </li>
            <li>{project ? project.name : ""} /</li>
            <li>{data.app_name ?? "New Application"}</li>
          </ul>
        </div>
        <div></div>
      </header>
      <form className="page-content-body" onSubmit={handleSubmit}>
        {step == 0 && reqs && reqs.start && (
          <FormBox>
            <FormBoxTitle title={reqs.start.title ?? "New Application"} />
            {getFields(reqs.start)}
            <FormBoxFooter>
              <div></div>
              <Button
                onClick={() => {
                  setStep(1);
                }}
              >
                Next Step
              </Button>
            </FormBoxFooter>
          </FormBox>
        )}

        {step == 1 && data.platform && currentPlatform && (
          <>
            <FormBox>
              <FormBoxTitle title={data.platform} />
              {getFields(currentPlatform)}
              <FormBoxFooter>
                <Button
                  mode="link"
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  Back
                </Button>
                <Button type="submit">Save Application</Button>
              </FormBoxFooter>
            </FormBox>
          </>
        )}
      </form>
    </>
  );
}
