import * as React from "react";
import "./LoadingOverlay.scss";
import { useApp } from "../../contexts/App.context";

export interface ILoadingOverlayProps {}

export function LoadingOverlay(props: ILoadingOverlayProps) {
  const { isLoading } = useApp();
  return (
    isLoading && (
      <div className="loading-overlay">
        <svg
          style={{
            margin: "auto",
            background: "none",
            display: "block",
            shapeRendering: "auto",
          }}
          width="60"
          height="60"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#03a9f4"
            stroke-width="15"
            r="29"
            stroke-dasharray="136.659280431156 47.553093477052"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="1.3s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
      </div>
    )
  );
}
