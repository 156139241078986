import * as React from "react";
import { IconFolder, ImgProject } from "../../config/Images";
import { useParams } from "react-router-dom";
import ProjectService from "../../api/services/Project.service";
import ContentShimmer from "react-content-shimmer";
import "./ApplicationView.scss";
import { GetProjectReqs } from "../../config/ProjectReq";
import ApplicationService from "../../api/services/Application.service";

export interface IApplicationViewProps {}

export function ApplicationView(props: IApplicationViewProps) {
  const { project_id, application_id } = useParams();
  const [project, setProject] = React.useState<any>(null);
  const [currentPlatform, setCurrentPlatform] = React.useState<any>(null);
  const reqs = GetProjectReqs();
  const [data, setData] = React.useState<any>(null);
  const projectService = new ProjectService();
  const service = new ApplicationService();

  interface IObject<T> {
    [index: string]: T;
  }

  const loadProject = async (id: any) => {
    const res = await projectService.show(id);

    if (res.data && res.data.success) {
      setProject(res.data.data);
    }
  };

  const loadApplication = async (id: any) => {
    const res = await service.show(id);

    if (res.data && res.data.success) {
      setData(res.data.data);

      let o: IObject<object> = reqs.req;

      if (o && o[res.data.data.platform]) {
        setCurrentPlatform(o[res.data.data.platform]);
      } else {
        alert("No Platform data");
      }
    }
  };

  React.useEffect(() => {
    setProject(null);

    if (project_id) {
      loadProject(project_id);
    }
  }, [project_id, application_id]);

  React.useEffect(() => {
    setData(null);

    if (application_id) {
      loadApplication(application_id);
    }
  }, [application_id]);

  const getInputByType = (item: any) => {
    let o: IObject<string> = JSON.parse(data.body);

    switch (item.type) {
      case "text":
      case "textarea":
      case "number":
      case "email":
        return (
          <>
            <tr>
              <td>{item.title}</td>
              <td>
                <span>{o[item.name]}</span>
              </td>
            </tr>
          </>
        );
        break;

      case "qty":
        return (
          <>
            <tr className="tr-title">
              <td colSpan={2}>{item.title}</td>
            </tr>
            {getFields(item)}
          </>
        );
        break;

      default:
        break;
    }
  };

  const getFields = (item: any) => {
    if (Object.hasOwn(item, "fields")) {
      return item.fields.map((f: any) => {
        return getInputByType(f);
      });
    } else {
      return <></>;
    }
  };

  return (
    <>
      <header className="page-header">
        <div>
          <ul className="breadcrumb">
            <li>
              <img src={data && data.img ? data.img : IconFolder} height={18} />
            </li>
            <li>{project ? project.name : ""} /</li>
            <li>{data && data.name ? data.name : "Untitled"}</li>
          </ul>
        </div>
        <div></div>
      </header>
      <div className="page-content-body">
        <div className="app-preview">
          <div className="left">
            {!data && (
              <ContentShimmer
                rows={2}
                rounded={"7px"}
                style={{
                  width: "90%",
                  height: "140px",
                  margin: "15px auto 15px auto",
                }}
              />
            )}

            {data && (
              <>
                <table className="preview-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>{getFields(currentPlatform)}</tbody>
                </table>
              </>
            )}
          </div>
          <div className="right project-side-view">
            {project && (
              <>
                <div className="img">
                  <img src={project.img ?? ImgProject} />
                </div>

                <h1 className="name">{project.name}</h1>
                <p className="desc">{project.description}</p>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
