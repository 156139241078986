import * as React from "react";
import * as XDialog from "@radix-ui/react-dialog";
import "./Dialog.scss";

export interface IDialogProps {
  title?: string;
  isOpen: any;
  setIsOpen: any;
  children: React.ReactNode;
}

export function Dialog(props: IDialogProps) {
  return (
    <XDialog.Root
      open={props.isOpen}
      onOpenChange={props.setIsOpen}
      modal={true}
      defaultOpen={true}
    >
      <XDialog.Portal>
        <XDialog.Overlay className="DialogOverlay" />
        <XDialog.Content className="DialogContent">
          <h1 className="DialogTitle">{props.title}</h1>
          {props.children}
        </XDialog.Content>
      </XDialog.Portal>
    </XDialog.Root>
  );
}
