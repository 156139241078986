import * as React from "react";
import "./Button.scss";

export interface IButtonProps {
  className?: string;
  children: React.ReactNode;
  onClick?: () => {} | void;
  type?: "button" | "submit";
  mode?: string;
}

export function Button(props: IButtonProps) {
  return (
    <button
      className={`${props.className ?? ""} ${props.mode ?? ""} btn`}
      onClick={() => {
        props.onClick && props.onClick();
      }}
      type={props.type ?? "button"}
    >
      {props.children}
    </button>
  );
}
