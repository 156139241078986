import * as React from "react";
import "./ImageUploader.scss";
import { ImgAddImg } from "../../config/Images";

export interface IImageUploaderProps {
  width?: string;
  height?: string;
  name: string;
  placeholder?: string;
  required?: any;
  onChange?: (e: any) => {} | void;
}

export function ImageUploader(props: IImageUploaderProps) {
  const [img, setImg] = React.useState(props.placeholder ?? ImgAddImg);

  const handleChange = (e: any) => {
    setImg(URL.createObjectURL(e.target.files[0]));
    props.onChange && props.onChange(e);
  };

  return (
    <label
      className="img-uploaded"
      style={{ width: props.width ?? "150px", height: props.height ?? "150px" }}
    >
      <img className="preview" src={img} />
      <input
        type="file"
        required={props.required ? true : false}
        name={props.name}
        hidden
        onChange={handleChange}
      />
    </label>
  );
}
