export const GetProjectReqs = () => {
  return {
    start: {
      title: "Create Application",
      fields: [
        {
          title: "Application Name",
          name: "app_name",
          type: "text",
        },
        {
          title: "Git Repository",
          name: "git_url",
          type: "text",
        },
        {
          title: "Application Description",
          name: "app_description",
          type: "textarea",
        },
        {
          title: "Select Platform",
          type: "radio_image_group",
          name: "platform",
          default: "Web (Laravel)",
          values: [
            {
              text: "Web (Laravel)",
              image: "/public_assets/img/laravel.png",
              type: "radio",
              value: "Web (Laravel)",
            },
            {
              text: "Web (NodeJS)",
              image: "/public_assets/img/nodejs.png",
              type: "radio",
              value: "Web (NodeJS)",
            },
            {
              text: "Web (ReactJS)",
              image: "/public_assets/img/react.png",
              type: "radio",
              value: "Web (ReactJS)",
            },
            {
              text: "Flutter",
              image: "/public_assets/img/flutter.png",
              type: "radio",
              value: "Flutter",
            },
          ],
        },
      ],
    },
    req: {
      "Web (Laravel)": {
        fields: [
          {
            title: "Host",
            type: "text",
            name: "host",
          },
          {
            title: "Has Stripe Account?",
            type: "qty",
            name: "lrvl_has_stripe",
            value: true,
            fields: [
              {
                title: "Stripe Private Key",
                type: "textarea",
                name: "stripe_pv_key",
              },
              {
                title: "Stripe Public Key",
                type: "textarea",
                name: "stripe_pb_key",
              },
            ],
          },
        ],
      },
      "Web (ReactJS)": {
        fields: [
          {
            title: "Host",
            type: "text",
            name: "host",
          },
          {
            title: "Has Stripe Account?",
            type: "qty",
            name: "has_stripe",
            value: true,
            fields: [
              {
                title: "Stripe Public Key",
                type: "textarea",
                name: "stripe_pb_key",
              },
            ],
          },
          {
            title: "Has Google Maps?",
            type: "qty",
            name: "has_gmaps",
            value: true,
            fields: [
              {
                title: "Google Maps API Key",
                type: "textarea",
                name: "google_maps_key",
              },
              {
                title: "Places API Key",
                type: "textarea",
                name: "places_maps_key",
              },
            ],
          },
        ],
      },
      Flutter: {},
    },
  };
};
