import React, { ReactNode, createContext, useContext, useState } from "react";

type ProviderProp = {
  children: React.ReactNode;
};

interface IAppContextProps {
  extraBtn?: any;
  setExtraBtn?: any;
  setIsLoading?: any;
  isLoading?: any;
}

const AppContext = createContext<IAppContextProps>({});

export function useApp() {
  return useContext(AppContext);
}

export function AppProvider({ children }: ProviderProp) {
  const [extraBtn, setExtraBtn] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AppContext.Provider
      value={{ extraBtn, setExtraBtn, isLoading, setIsLoading }}
    >
      {children}
    </AppContext.Provider>
  );
}
