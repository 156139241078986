import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import { Button } from "../../Button/Button";
import "./Nav.scss";
import { useApp } from "../../../contexts/App.context";
export interface INavProps {}

export function Nav(props: INavProps) {
  const { extraBtn } = useApp();
  return (
    <nav className="main-nav">
      <div className="left">
        <div className="brand">
          <h2>Divcodes</h2>
        </div>

        <div className="extra-box">
          {extraBtn && (
            <Button
              className="btn"
              onClick={() => {
                extraBtn.action();
              }}
            >
              {extraBtn.text}
            </Button>
          )}
        </div>
      </div>

      <div className="middle">
        <ul className="links">
          <li>
            <NavLink to="/projects">Projects</NavLink>
          </li>
          <li>
            <NavLink to="/docs">Documentation</NavLink>
          </li>
        </ul>
      </div>

      <div className="right"></div>
    </nav>
  );
}
