import React from "react";
import "./App.scss";
import { Nav } from "./components/layout/Nav/Nav";
import { Footer } from "./components/layout/Footer/Footer";
import { ProjectsView } from "./views/projects/ProjectsView";
import { BrowserRouter } from "react-router-dom";
import { Navigate, redirect, Route, Routes } from "react-router";
import { DocsView } from "./views/docs/DocsView";
import { AppProvider } from "./contexts/App.context";
import { DocsCreate } from "./views/docs/DocsCreate";
import { LoadingOverlay } from "./components/LoadingOverlay/LoadingOverlay";
import { ApplicationCreate } from "./views/projects/ApplicationCreate";

function App() {
  return (
    <div className="app">
      <AppProvider>
        <BrowserRouter>
          <Nav />
          <LoadingOverlay />
          <div className="app-content">
            <Routes>
              <Route path="/" index element={<Navigate to="/projects" />} />
              <Route path="/projects" element={<ProjectsView />} />
              <Route
                path="/projects/create/:project_id"
                element={<ProjectsView />}
              />
              <Route
                path="/projects/view/:project_id/:application_id"
                element={<ProjectsView />}
              />
              <Route path="/docs" element={<DocsView />} />
              <Route path="/docs/:doc_id" element={<DocsView />} />
              <Route
                path="/docs/create/:docfolder_id"
                element={<DocsCreate />}
              />
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </AppProvider>
    </div>
  );
}

export default App;
