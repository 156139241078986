import * as React from "react";
import "./ProjectsView.scss";
import { TreeView } from "../../components/TreeView/TreeView";
import { ITreeViewItemProps } from "../../components/TreeView/TreeViewItem";
import { useApp } from "../../contexts/App.context";
import { CUProjectModal } from "../../modals/projects/CUProject.modal";
import ProjectService from "../../api/services/Project.service";
import IProjectGetResponse from "../../api/interfaces/IProjectGetResponse";
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationCreate } from "./ApplicationCreate";
import { ApplicationView } from "./ApplicationView";

export interface IProjectsViewProps {}

export function ProjectsView(props: IProjectsViewProps) {
  const { setExtraBtn } = useApp();
  const navigate = useNavigate();
  const { project_id, application_id } = useParams();
  const [isNewModalOpen, setIsNewModalOpen] = React.useState(false);
  const [projects, setProjects] =
    React.useState<Array<IProjectGetResponse> | null>(null);

  const [tree, setTree] = React.useState<Array<ITreeViewItemProps> | null>(
    null
  );
  const service = new ProjectService();

  const load: any = async () => {
    const res = await service.get();

    if (res.data && res.data.success) {
      setProjects(res.data.data);
    }
  };

  React.useEffect(() => {
    setExtraBtn({
      text: "New Project",
      action: () => {
        setIsNewModalOpen(isNewModalOpen ? false : true);
      },
    });

    load();

    return () => {
      setExtraBtn(null);
    };
  }, []);

  React.useEffect(() => {
    const loadTree = () => {
      let o: Array<ITreeViewItemProps> = [
        {
          title: "Projects",
          type: "label",
        },
      ];

      if (projects) {
        projects.forEach((p) => {
          o.push({
            id: p.id,
            title: p.name,
            img: p.img,
            nodes: p.apps
              ? p.apps.map((a) => {
                  return {
                    id: a.id,
                    title: a.name,
                    img: a.img ?? "",
                  };
                })
              : [],
          });
        });

        setTree(o);
      }
    };

    loadTree();
  }, [projects]);

  const handleCreateSuccess = () => {
    load();
  };

  const hanleItemOnClick = (e: ITreeViewItemProps) => {
    if (e.isRoot) {
    } else {
      if (e.parent) navigate(`/projects/view/${e.parent.id}/${e.id}`);
    }
  };

  return (
    <>
      <CUProjectModal
        onSuccess={handleCreateSuccess}
        isOpen={isNewModalOpen}
        setIsOpen={setIsNewModalOpen}
      />

      <div className="page with-sidebar">
        <aside className="sidebar">
          <TreeView
            itemOnClick={hanleItemOnClick}
            type="projects"
            addBtnUrl="/projects/create"
            nodes={tree}
          />
        </aside>

        <div className="page-content">
          {project_id && !application_id && <ApplicationCreate />}
          {project_id && application_id && <ApplicationView />}
        </div>
      </div>
    </>
  );
}
