import axios from "axios";

export default class BaseService {
//   root = "http://127.0.0.1:8000/dashboard/";
  root = "https://workspace-api.divcodes.com/dashboard/";

  _headers = () => {
    return {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };
  };

  _get = async (url: string) => {
    const res = await axios({
      url: url,
      headers: this._headers(),
      method: "get",
    });

    return res;
  };

  _post = async (url: string, body: any) => {
    const res = await axios({
      url: url,
      headers: this._headers(),
      method: "post",
      data: body,
    });

    return res;
  };
}
