import * as React from "react";
import "./DocsCreate.scss";
import { useApp } from "../../contexts/App.context";
import DocService from "../../api/services/Doc.service";
import { useNavigate, useParams } from "react-router-dom";
import MarkdownEditor from "@uiw/react-markdown-editor";
import { IconFolder } from "../../config/Images";
import { Button } from "../../components/Button/Button";
import DocFolderService from "../../api/services/DocFolder.service";

export interface IDocsViewProps {}

export function DocsCreate(props: IDocsViewProps) {
  const { setIsLoading } = useApp();
  const service = new DocService();
  const docfolderService = new DocFolderService();
  const navigate = useNavigate();
  const { docfolder_id } = useParams();
  const [docfolder, setDocfolder] = React.useState<any>(null);
  const [data, setData] = React.useState({
    title: "",
    body: "",
    doc_folder_id: "",
  });

  const load = async () => {
    const res = await docfolderService.show(docfolder_id);
    if (res.data && res.data.success) {
      setDocfolder(res.data.data);
      setData({
        ...data,
        doc_folder_id: docfolder_id ?? "",
      });
    }
  };

  React.useEffect(() => {
    if (!docfolder_id) {
      navigate("/docs");
    }

    load();
  }, []);

  const handleChange = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleMDChange = (v: string) => {
    setData({
      ...data,
      body: v,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await service.store(data);

    setIsLoading(false);
    if (res.data && res.data.success) {
      navigate("/docs/" + res.data.data.id);
    }
  };

  return (
    <>
      <div className="page">
        <form onSubmit={handleSubmit} className="page-content">
          <header className="page-header">
            <div>
              <ul className="breadcrumb">
                <li>
                  <img src={IconFolder} height={18} />
                </li>
                <li>{docfolder ? docfolder.name : ""} /</li>
                <li>
                  <input
                    type="text"
                    placeholder="Document Title"
                    name="title"
                    value={data.title}
                    onChange={handleChange}
                    required
                  />
                </li>
              </ul>
            </div>
            <div>
              <Button type="submit">Save Document</Button>
            </div>
          </header>
          <div className="page-content-body">
            <MarkdownEditor
              visible
              autoFocus
              value={data.body}
              onChange={handleMDChange}
            />
          </div>
        </form>
      </div>
    </>
  );
}
