import * as React from "react";
import "./Input.scss";

export interface IInputProps {
  className?: string;
  placeholder?: string;
  type?: string;
  name?: string;
  required?: any;
  value?: any;
  onChange?: (e: any) => {} | void;
}

export function Input(props: IInputProps) {
  if (props.type == "textarea") {
    return (
      <textarea
        className={`${props.className ?? ""} input`}
        placeholder={props.placeholder ?? ""}
        name={props.name ?? ""}
        rows={4}
        required={props.required ? true : false}
        onChange={(e: any) => {
          props.onChange && props.onChange(e);
        }}
      >
        {props.value ?? ""}
      </textarea>
    );
  }

  return (
    <input
      className={`${props.className ?? ""} input`}
      placeholder={props.placeholder ?? ""}
      type={props.type ?? "text"}
      name={props.name ?? ""}
      value={props.value}
      required={props.required ? true : false}
      onChange={(e: any) => {
        props.onChange && props.onChange(e);
      }}
    />
  );
}
