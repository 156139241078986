import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { IconFolder, ImgProject } from "../../config/Images";
import { LoadTreeList } from "./TreeView";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useNavigate } from "react-router-dom";

export interface ITreeViewItemProps {
  id?: number;
  img?: string;
  title: string;
  nodes?: Array<ITreeViewItemProps>;
  type?: string;
  listType?: string;
  isRoot?: boolean;
  parent?: ITreeViewItemProps;
  addBtnUrl?: string | null;
  onClick?: (e: any) => {} | void;
}

export function TreeViewItem(props: ITreeViewItemProps) {
  const [isOpened, setIsOpened] = React.useState(false);
  const navigate = useNavigate();

  function getChild(n: ITreeViewItemProps) {
    if (!n.nodes) return;
    return n.nodes.map((node) => {
      return LoadTreeList(node, "", false, null, props.onClick, props);
    });
  }

  function getIcon() {
    if (props.img) return props.img;
    let icon = "";
    switch (props.listType) {
      case "projects":
        icon = ImgProject;
        break;

      case "docs":
        icon = IconFolder;
        break;

      default:
        icon = "";
        break;
    }

    return icon;
  }

  return props.type == "label" ? (
    <li className="label">{props.title}</li>
  ) : (
    <li
      className={
        (props.nodes && props.nodes.length > 0 ? "with-nodes" : "") +
        " " +
        (isOpened ? "opened" : "")
      }
      key={props.id ?? 0}
    >
      <div
        className={" i"}
        onClick={() => {
          setIsOpened(!isOpened);
          props.onClick && props.onClick(props);
        }}
      >
        <img src={getIcon()} />
        <span>{props.title}</span>
        {props.nodes && props.nodes.length > 0 && (
          <FontAwesomeIcon className="arrow" icon={solid("angle-down")} />
        )}

        {props.isRoot && (
          <>
            <button
              className="add-btn"
              onClick={() => {
                props.addBtnUrl && navigate(props.addBtnUrl);
              }}
            >
              <FontAwesomeIcon icon={solid("plus")} />
            </button>
            <button className="edit-btn">
              <FontAwesomeIcon icon={solid("pencil")} />
            </button>
          </>
        )}
      </div>
      <ul>{props.nodes && getChild(props)}</ul>
    </li>
  );
}
