import * as React from "react";
import { ImgProject } from "../../config/Images";
import "./TreeView.scss";
import { ITreeViewItemProps, TreeViewItem } from "./TreeViewItem";
import ContentShimmer from "react-content-shimmer";

export interface ITreeViewProps {
  nodes?: Array<ITreeViewItemProps> | null;
  selected?: string;
  type?: string;
  addBtnUrl?: string;
  itemOnClick: (e: any) => {} | void;
}

export function LoadTreeList(
  item: ITreeViewItemProps,
  type: string = "",
  isRoot: boolean = false,
  addBtnUrl: string | null = null,
  itemOnClick?: (e: any) => {} | void,
  parent?: ITreeViewItemProps
) {
  const element = (
    <TreeViewItem
      id={item.id}
      title={item.title}
      nodes={item.nodes}
      img={item.img}
      type={item.type}
      listType={type}
      isRoot={isRoot}
      parent={parent}
      addBtnUrl={
        addBtnUrl + (type == "docs" || "projects" ? `/${item.id}` : "")
      }
      onClick={(e: any) => {
        itemOnClick && itemOnClick(e);
      }}
    />
  );

  return element;
}

export function TreeView(props: ITreeViewProps) {
  return (
    <div className="tree-par">
      {props.nodes == null ? (
        <ContentShimmer
          rows={5}
          rounded={"7px"}
          style={{
            width: "90%",
            height: "40px",
            margin: "15px auto 15px auto",
          }}
        />
      ) : (
        <ul className={(props.type ?? "") + " tree-root"}>
          {props.nodes &&
            props.nodes?.map((n) => {
              return LoadTreeList(
                n,
                props.type,
                true,
                props.addBtnUrl,
                props.itemOnClick
              );
            })}
        </ul>
      )}
    </div>
  );
}
