import * as React from "react";

export interface IImageRadioItemProps {
  text?: string;
  image: string;
  value: string;
  name?: string;
  required?: any;
  checked?: boolean;
  type?: "radio";
  onChange?: (e: IImageRadioItemProps) => {} | void;
}

export function ImageRadioItem(props: IImageRadioItemProps) {
  const handleChange = (e: any) => {
    props.onChange &&
      props.onChange({
        ...props,
        type: "radio",
      });
  };

  return (
    <label>
      <input
        hidden
        type="radio"
        name={props.name}
        required={props.required ? true : false}
        checked={props.checked}
        onChange={handleChange}
      />
      <div className="img">
        <img src={props.image} />
      </div>
      {props.text && <span>{props.text}</span>}
    </label>
  );
}
