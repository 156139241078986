import BaseService from "./Base.service";

export default class ResourceService extends BaseService {
  resource = "";

  get: any = async () => {
    return await this._get(this.root + this.resource);
  };

  show: any = async (id: any) => {
    return await this._get(this.root + this.resource + "/" + id);
  };

  store: any = async (body: any) => {
    return await this._post(this.root + this.resource, body);
  };
}
