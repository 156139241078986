import * as React from "react";
import "./ImageRadio.scss";
import { IImageRadioItemProps, ImageRadioItem } from "./ImageRadioItem";

export interface IImageRadioGroupProps {
  items: Array<IImageRadioItemProps>;
  title?: string;
  name: string;
  required?: any;
  checked?: string;
  onChange: (e: IImageRadioItemProps) => {} | void;
}

export function ImageRadioGroup(props: IImageRadioGroupProps) {
  const handleChange = (e: IImageRadioItemProps) => {
    props.onChange(e);
  };

  return (
    <>
      {props.title && <span className="input-label">{props.title}</span>}
      <div className="image-radio-group">
        {props.items.map((item) => {
          return (
            <>
              <ImageRadioItem
                required={props.required}
                name={props.name}
                checked={props.checked == item.value}
                onChange={handleChange}
                value={item.value}
                text={item.text}
                image={item.image}
              />
            </>
          );
        })}
      </div>
    </>
  );
}
